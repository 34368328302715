<!--Navbar Start-->
<nav
  class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  (window:scroll)="windowScroll()"
  id="navbar"
>
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img
        src="assets/images/logo-dark.png"
        alt=""
        class="logo-dark"
        height="80"
      />
    </a>
    
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
    >
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{ active: currentSection === 'home' }">
          <a
            [ngxScrollTo]="'#home'"
            href="javascript: void(0);"
            class="nav-link"
            >Главная</a
          >
        </li>
        <li
          class="nav-item"
          [ngClass]="{ active: currentSection === 'about-us' }"
        >
          <a
            [ngxScrollTo]="'#about-us'"
            href="javascript: void(0);"
            class="nav-link"
            >О компании</a
          >
        </li>
        <li
          class="nav-item"
          [ngClass]="{ active: currentSection === 'projects' }"
        >
          <a
            [ngxScrollTo]="'#projects'"
            href="javascript: void(0);"
            class="nav-link"
            >Продукты</a
          >
        </li>
        <li
          class="nav-item"
          [ngClass]="{ active: currentSection === 'technologies' }"
        >
          <a
            [ngxScrollTo]="'#technologies'"
            href="javascript: void(0);"
            class="nav-link"
            >Технологии</a
          >
        </li>
        <li
          class="nav-item"
          [ngClass]="{ active: currentSection === 'contacts' }"
        >
          <a
            [ngxScrollTo]="'#contacts'"
            href="javascript: void(0);"
            class="nav-link"
            >Контакты</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar End -->
<div
  appScrollspy
  [spiedTags]="['SECTION']"
  (sectionChange)="onSectionChange($event)"
>
  <itw-home></itw-home>
  <itw-about-us></itw-about-us>
  <itw-projects></itw-projects>
  <itw-technologies></itw-technologies>
  <itw-contacts></itw-contacts>
</div>
