<!-- Footer start -->
<section id="contacts">
  <footer class="footer">
    <div class="container">
      <!-- <div
        class="uFont f-title3-white upper"
        style="padding-left: 10%; margin-bottom: 100px"
      >
        Связаться с нами
      </div> -->
      <div class="contactsDiv flex wrap sb">
        <a
          class="logo text-uppercase flex verticalCenter"
          href="/"
          style="margin-right: 20px"
        >
          <img
            src="assets/images/logo-dark.png"
            alt=""
            class="logo-dark"
            height="150"
          />
        </a>
        <div class="fixWidth2 flexColumn VCenter" style="margin-right: 20px">
          <div class="rFont f-title1-white" style="margin-bottom: 10px">
            ITworks
          </div>
          <div class="rFont f-body3-white">
            Мы занимаемся разработкой с 2008 года. За время работы мы получили
            экспертные знания по работе с высоконагруженными системами
            постоянного доступа.
          </div>
        </div>

        <div class="fixWidth2 flexColumn contacts">
          <div class="rFont f-body1-white" style="margin-bottom: 10px">
            Контакты
          </div>
          <div class="flex" style="margin-bottom: 10px">
            <i-feather
              name="map-pin"
              class="icon-dual-light icons-sm"
              style="margin-right: 20px"
            ></i-feather>
            <div class="rFont f-body3-white">
              Беларусь, г. Брест, ул. Куйбышева 13А/2-7, 224016
            </div>
          </div>
          <div class="flex" style="margin-bottom: 10px">
            <i-feather
              name="mail"
              class="icon-dual-light icons-sm"
              style="margin-right: 20px"
            ></i-feather>

            <a href="mailto:info@itworks.by" class="rFont f-body3-white"
              >info@itworks.by</a
            >
          </div>
          <div class="flex">
            <i-feather
              name="phone"
              class="icon-dual-light icons-sm"
              style="margin-right: 20px"
            ></i-feather>
            <div class="flexColumn" class="rFont f-body3-white">
              <div>+375 (44) 739-90-04</div>
              <div>+375 (162) 25-82-00</div>
              <div>+375 (162) 53-17-23</div>
            </div>
          </div>
          <div class="download_policy">
            <div class="rFont f-body1-white">Вы можете ознакомиться</div>
            <a href="../../../assets/politika_po_obrabotke_pd.pdf" download>Политика конфиденциальности</a>
            <a href="../../../assets/polojenie_o_poryadke_obrabotki_pd.pdf" download>Положение о порядке обработки персональных данных</a>
          </div>
        </div>
      </div>

     
      <!-- end row -->
      <p class="flex VCenter rFont f-body3-white" style="margin-top: 20px">
        {{ year }} © Itworks
      </p>
    </div>
    <!-- end container -->
  </footer>
  <!-- Footer end -->
</section>
