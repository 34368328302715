import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itw-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
/**
 * Features component
 */
export class ProjectsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
