<div *ngIf="open" class="viewer-container" id="image-viewer">
  <div class="viewer-content">
    <!-- <a class="close-button" (click)="close.emit()"> Close Image </a> -->
    <span
      class="close-button rounded-circle bg-soft-primary"
      (click)="close.emit()"
    >
      <i-feather name="x" class="icon-dual-danger"></i-feather>
    </span>
    <img class="big-img" [src]="imageURL" />
  </div>
</div>
