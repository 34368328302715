import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itw-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss'],
})
export class TechnologiesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
