var createDate = new Date(2008, 0, 21);
var currentDate = new Date();

var years =
  new Date(currentDate.getTime() - createDate.getTime()).getFullYear() - 1970;

const serviceData = [
  {
    icon: 'clock',
    title: years + ' лет на IT рынке',
    text: '',
  },
  {
    icon: 'users',
    title: 'Штат сотрудников 50+ специалистов',
    text: '',
  },
  {
    icon: 'arrow-up',
    title: 'Высокие компетенции в финтехе',
    text: '',
  },
];

export { serviceData };
