import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itw-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
/**
 * Footer component
 */
export class ContactsComponent implements OnInit {
  year = new Date().getFullYear();

  constructor() {}

  ngOnInit(): void {}
}
