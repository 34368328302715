import { Component, OnInit } from '@angular/core';
import { Services } from './about-us.model';
import { serviceData } from './data';

@Component({
  selector: 'itw-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
/**
 * Services component
 */
export class AboutUsComponent implements OnInit {
  serviceData: Services[];
  viewerOpenRus: boolean = false;
  viewerOpenEng: boolean = false;
  isoRusUrl = 'assets/images/iso-rus.jpg';
  isoEngUrl = 'assets/images/iso-rus-2.jpg';

  constructor() { }

  ngOnInit(): void {
    // fetches the data
    this._fetchData();
  }

  /**
   * Service data
   */
  private _fetchData() {
    this.serviceData = serviceData;
  }
}
