<!-- Testimonial start -->
<section
  class="section bg-white"
  id="technologies"
  style="margin-bottom: 100px"
>
  <div class="container">
    <div
      class="uFont f-title3-main upper"
    >
      Технологии
    </div>
    <div
      class="rFont f-body1-black"
      style="max-width: 820px; margin-bottom: 50px"
    >
      Стек используемых технологий постоянно расширяется и охватывает как
      современные решения, так и наиболее распространенные
    </div>
    <!-- end row -->

    <div class="technologies_grid">
      <img
        src="assets/images/technologies/1.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/2.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/3.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/4.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/5.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/6.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/7.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/8.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/9.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/10.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
      <img
        src="assets/images/technologies/11.png"
        alt="client-img"
        class="mx-auto img-fluid d-block"
      />
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Testimonial end -->
