<!-- Features start -->
<section class="section products" id="projects">
  <div class="container">
    <div
      class="uFont f-title3-main upper"
    >
      Продукты
    </div>


    <div class="flex flexColumn product_outsource">
      
      <div class="rowText rFont f-body1-black">
        Опыт разработки аутсорсинговых проектов позволил нам получить экспертные
        знания в таких направлениях, как:
      </div>
      <div class="flex">
        <div class="rowList">
          <div class="listItem flex rFont f-body1-black animate_product1 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">
              Информационные и транзакционные банковские системы для обслуживания
              физических и юридических лиц
            </div>
          </div>
          <div class="listItem flex rFont f-body1-black animate_product2 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">Интеграция с банковскими системами</div>
          </div>
          <div class="listItem flex rFont f-body1-black item-3 animate_product3 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">
              Системы потребительского кредитования — обеспечение on-line
              кредитования в магазинах
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- end row -->
    <div class="flexColumn product_our" style="margin: 70px 0">
      <div class="rowTitle uFont f-title3-black2 upper">
        Собственные продукты
      </div>
      
      <div class="flex product_our">
       
        <div class="img_container">
          <img src="assets/images/our-projects.png" />
        </div>
        <div class="flexColumn rFont f-body1-black flexColumn subscribe">
          <div class="text">
            Мы не упускаем возможности реализовать свои собственные идеи. Одной из
            таких идей стал ITдом — программный продукт для управления
            товариществом собственников домовладений
          </div>
          <div class="flex moreLink rFont f-body2-black">
            <a href="https://itdom.by">Подробнее...</a>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->
