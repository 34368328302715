<!-- Services start -->
<section class="section bg-white" id="about-us">
  <div class="container">
    <div
      class="uFont f-title3-main upper"
    >
      Почему выбирают нас
    </div>
    <div
      class="rFont f-body1-black"
      style="max-width: 820px; margin-bottom: 50px"
    >
      Наша компания появилась в 2008 году. С тех пор мы получили незаменимые
      навыки в работе со сложными, высоконагруженными системами. Благодаря
      сотрудничеству с крупными банками мы выработали принципы эффективной
      разработки сложных логических систем, рассчитанных на высокую нагрузку.
    </div>
    <div class="flex gridRow animate_container animate_show">
      <div class="rowTitle rFont f-title3-black2 upper">
        Разработка сложных систем
      </div>
      <div class="rowText rFont f-body1-black">
        Компания имеет опыт разработки высоконагруженных систем, требующих
        постоянного доступа
      </div>
    </div>
    <div class="flex gridRow right animate_container animate_show">
      <div class="rowTitle rFont f-title3-black2 upper">
        более 10 лет на IT рынке
      </div>
      <div class="rowText rFont f-body1-black">
        Большой опыт работы с различными системами позволяет нам находить
        оптимальные решения даже в самых сложных проектах
      </div>
    </div>
    <div class="flex gridRow animate_container animate_show">
      <div class="rowTitle rFont f-title3-black2 upper">
        Высокие компетенции в финтехе
      </div>
      <div class="rowText rFont f-body1-black">
        Работа в сфере финансов позволила нам разработать эффективные принципы
        построения сложных финансовых систем
      </div>
    </div>
    <div class="flex gridRow right animate_container animate_show">
      <div class="rowTitle rFont f-title3-black2 upper">
        Профессиональная команда
      </div>
      <div class="rowText rFont f-body1-black">
        Мы собрали команду профессиональных сотрудников каждый из которых —
        специалист в своей области
      </div>
    </div>
  </div>
  <div class="isoDiv" style="padding: 20px 0px; margin-top: 130px">
    <div class="container">
      <div class="fixHeigth1 flex gridRow" style="margin-bottom: 0px">
        <div class="rowTitle uFont f-title3-white upper">Сертификация</div>
        <div class="fixWidth1 rowText rFont f-body1-white">
          Внедрена и сертифицирована на соответствие ISO 9000:2015 система
          менеджмента качества. Ежегодно успешно проходим подтверждающие
          соответствие аудиты
        </div>

        <div class="sertificate flex grow row">
          <div class="col-lg-12">
            <div class="flex document">
              <div
                style="
                  margin-right: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <itw-image-viewer
                  [imageURL]="isoRusUrl"
                  [open]="viewerOpenRus"
                  (close)="viewerOpenRus = false"
                ></itw-image-viewer>
                <div class="image-container animate_image animate_show" (click)="viewerOpenRus = true">
                  <img [src]="isoRusUrl" />
                </div>
              </div>

              <div
                style="
                  margin-right: 20px !important;
                  margin-bottom: 20px !important;
                "
              >
                <itw-image-viewer
                  [imageURL]="isoEngUrl"
                  [open]="viewerOpenEng"
                  (close)="viewerOpenEng = false"
                ></itw-image-viewer>
                <div class="image-container animate_image animate_show" (click)="viewerOpenEng = true">
                  <img [src]="isoEngUrl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <!-- end container -->
</section>
<!-- Services end -->
