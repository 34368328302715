<!-- Hero section Start -->
<div class="bgImage"></div>
<section class="hero-section flexColumn VCenter" id="home">
  <div class="container">
    <div
      class="flex"
      style="margin-bottom: 30px; -ms-flex-wrap: wrap; flex-wrap: wrap"
    >
      <div class="uFont f-tittle1-white">ITworks</div>
    </div>
    <div class="uFont f-body2-white" style="max-width: 800px">
      Проектирование сложных финансовых систем, индивидуальный подход, система
      менеджмента качества
    </div>
  </div>
  <!-- end container -->
</section>
<!-- Hero section End -->
