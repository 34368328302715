import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountToModule } from 'angular-count-to';
import { FeatherModule } from 'angular-feather';
import {
  ArrowUp,
  Check,
  Clock,
  Grid,
  Home,
  Mail,
  MapPin,
  Phone,
  Users,
  X,
} from 'angular-feather/icons';
import { HomeComponent } from 'src/app/shared/home/home.component';
import { ImageViewerComponent } from 'src/app/shared/image-viewer/image-viewer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ProjectsComponent } from './projects/projects.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { TechnologiesComponent } from './technologies/technologies.component';

const icons = {
  Clock,
  Users,
  ArrowUp,
  X,
  Grid,
  Home,
  Check,
  MapPin,
  Mail,
  Phone,
};

@NgModule({
  declarations: [
    HomeComponent,
    AboutUsComponent,
    ProjectsComponent,
    TechnologiesComponent,
    ContactsComponent,
    ImageViewerComponent,
    ScrollspyDirective,
  ],
  imports: [CommonModule, FeatherModule.pick(icons), CountToModule],
  exports: [
    HomeComponent,
    AboutUsComponent,
    ProjectsComponent,
    TechnologiesComponent,
    ContactsComponent,
    ScrollspyDirective,
    FeatherModule,
  ],
})
export class SharedModule {}
